import { Box, Button, ListItem, ListItemText, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { authService } from '../../services/authentication';
import { styles } from '../../theme/styles';

const MobileMenu = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n } = useTranslation();

  const isContracting = props.history.location.pathname.split('/')[1].slice(0, 8) === 'contract';

  const url = process.env.REACT_APP_MULTISPORT_URL || 'https://tesztcontract.multisportpass.hu';
  const intranet = `${process.env.REACT_APP_INTRA_URL || 'https://intranet2.mbsm.hu'}${i18n.language === 'en' ? '/en' : ''}/intranet`;

  // 2022-01-06 pjung TNRUKAYCM-2342 FLEXI hónap link
  const { classes, disabled, currentUser: almafa } = props;
  const user = authService.currentUser();
  const urlink = `${intranet}/flexi/${almafa.kr}/${almafa.tax}/${user.firstName} ${user.lastName}/${user.email}`;
  const url2link = `${url}${i18n.language === 'en' ? '/en' : ''}/multisport/creditvasarlas/${almafa.kr}/${almafa.cardNumber}`;
  const ma = new Date().toISOString().substring(0, 10);
  // 2022-05-10 pjung TNRUKAYCM-1395 FLEXI menüpont "Szü" státusz esetén is...
  const kelleLink = almafa.kr && almafa.tax && ma >= almafa.from && ma <= almafa.to && (almafa.cardStatus === '1' || almafa.cardStatus === '9');
  const joker = ma <= '2025-01-15' && `${intranet}/tars-hosszabbitas`;

  const kelleTars = !!almafa.tars;
  const calcTarsUrl = (kr, tax) => {
    if (!(kr && tax)) return '';
    const r1 = Math.floor(Math.random() * 89) + 11;
    const r2 = Math.floor(Math.random() * 6) + 4;
    console.log('KR', kr, 'adó', tax, 'R1', r1, 'R2', r2);
    const s1 = kr.split('').reduce((sum, value) => (sum += value - 0), 0);
    const s2 = tax.split('').reduce((sum, value) => (sum += value - 0), 0);
    const sum = (s1 + s2 + r1) % r2;
    return (kr?.length === 5 ? '0' : '') + kr + tax + r1 + r2 + sum;
  };
  const tarsLink = `${t(almafa.tarsEmail ? 'tars.upgrade' : 'tars.invite')}${
    // eslint-disable-next-line eqeqeq
    almafa.ev == ma.substring(0, 4) ? '' : ' (' + almafa.ev + ')'
  }`;
  const tarsUrl = `${intranet}/tarskartyas-szerzodeskotes/${calcTarsUrl(almafa.kr, almafa.tax)}/${user.email}`;

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ position: 'absolute', top: '16px', right: '32px' }}>
        <MenuIcon color="primary" fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        style={{ zIndex: 1450 }}
        PaperProps={{ style: { width: '75%', maxWidth: '400px' } }}
        onClose={handleClose}
        onClick={handleClose}>
        <ListItem
          disableGutters
          button
          className={classes.mobileMenuItem}
          onClick={() => {
            props.history.push('/payment');
          }}>
          <ListItemText primary={t('menu.payment')} />
        </ListItem>
        <ListItem
          button
          className={classes.mobileMenuItem}
          onClick={() => {
            props.history.push('/profile/data');
          }}>
          <ListItemText primary={t('menu.profil.data')} />
        </ListItem>
        <ListItem
          button
          className={classes.mobileMenuItem}
          onClick={() => {
            props.history.push('/profile/chpass');
          }}>
          <ListItemText primary={t('menu.profil.chpass')} />
        </ListItem>
        {/* 2021-03-23 pjung TNRUKAYCM-1039 AYCM és MultiSport almenük helyett */}
        <ListItem
          style={props.history.location.pathname === '/contracting/init' ? { color: 'rgba(49, 139, 129, 1)' } : { color: 'rgba(49, 139, 129, 1)' }}
          button
          className={classes.primaryMenuItemMobile}
          onClick={() => {
            props.history.push('/contracting/init');
          }}>
          <ListItemText primary={`AYCM ${t('menu.contracting')}`} />
        </ListItem>
        <Box display={disabled ? 'none' : 'block'}>
          <ListItem
            style={props.history.location.pathname === '/contracting/upgrade' ? { color: 'rgb(234, 0, 138)' } : { color: 'rgba(49, 139, 129, 1)' }}
            button
            className={classes.primaryMenuItemMobile}
            onClick={() => {
              props.history.push('/contracting/upgrade');
            }}>
            <ListItemText primary={`AYCM ${t('menu.contract.update')}`} />
          </ListItem>
        </Box>
        {/* 2023-12-05 pjung TNRUKAYCM-1752 */}
        <Box display={kelleTars ? 'block' : 'none'}>
          <ListItem
            cr
            style={
              props.history.location.pathname.startsWith('/contracting/upgrade_tars/') || (isContracting && sessionStorage.getItem('type') === 'U')
                ? { color: 'rgba(49, 139, 129, 1)' }
                : { color: 'rgba(49, 139, 129, 1)' }
            }
            button
            className={classes.primaryMenuItemMobile}
            onClick={() => {
              if (almafa.tarsEmail) {
                // Upgrade
                props.history.push(`/contracting/upgrade_tars/${almafa.tarsKr}/${almafa.tarsEmail}`);
              } // Meghívás
              else window.open(tarsUrl);
            }}>
            <ListItemText primary={tarsLink} />
          </ListItem>
        </Box>
        <ListItem
          style={{ color: 'rgba(234, 0, 138, 1)' }}
          button
          className={classes.primaryMenuItemMobile}
          onClick={() => {
            window.open(`${url}/multisport/entitlement-verification`);
          }}>
          <ListItemText primary={`MultiSport ${t('menu.contracting')}`} />
        </ListItem>
        <Box display={disabled ? 'none' : 'block'}>
          <ListItem
            style={props.history.location.pathname === '/contracting/upgrade' ? { color: 'rgb(234, 0, 138)' } : { color: 'rgba(234, 0, 138, 1)' }}
            button
            className={classes.primaryMenuItemMobile}
            onClick={() => {
              props.history.push('/contracting/upgrade_multi');
            }}>
            <ListItemText primary={`MultiSport ${t('menu.contract.update')}`} />
          </ListItem>
        </Box>
        {/* 2022-09-19 pjung TNRUKAYCM-1433 Új menüpont */}
        <ListItem
          style={{ color: 'rgba(234, 0, 138, 1)' }}
          button
          className={classes.primaryMenuItemMobile}
          onClick={() => {
            window.open(url2link);
          }}>
          <ListItemText primary={t('menu.extra')} />
        </ListItem>

        <ListItem
          button
          className={classes.mobileMenuItem}
          onClick={() => {
            props.history.push('/invoicedata');
          }}>
          <ListItemText primary={t('menu.contract.invoicedata')} />
        </ListItem>
        <ListItem
          button
          className={classes.mobileMenuItem}
          onClick={() => {
            props.history.push('/invoices');
          }}>
          <ListItemText primary={t('menu.contract.invoices')} />
        </ListItem>
        <ListItem
          button
          className={classes.mobileMenuItem}
          onClick={() => {
            props.history.push('/documents');
          }}>
          <ListItemText primary={t('menu.contract.documents')} />
        </ListItem>

        {
          /* 2021-07-02 pjung TNRUKAYCM-1184 Rendkívüli szerződéskötés menüpont levétele
             2021-12-08 pjung TNRUKAYCM-1300 Rendkívüli menüpont visszatétele
             2022-01-05 pjung TNRUKAYCM-1342 FLEXI hónap link */
          kelleLink && (
            <ListItem
              button
              className={classes.mobileMenuItem}
              style={{ color: 'red' }}
              onClick={() => {
                window.open(urlink);
              }}>
              <ListItemText primary={t('menu.reactivation')} />
            </ListItem>
          )
        }
        {
          /* 2022-11-22 pjung TNRUKAYCM-1473 Joker menüpont */
          joker && (
            <ListItem
              button
              className={classes.mobileMenuItem}
              style={{ color: 'red' }}
              onClick={() => {
                window.open(joker);
              }}>
              <ListItemText primary={t('menu.joker')} />
            </ListItem>
          )
        }

        <Box
          style={{
            textAlign: 'center',
            marginTop: '16px',
            marginBottom: '8px'
          }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              authService.logout();
              props.history.push('/login');
            }}
            style={{ padding: '4px' }}>
            {t('menu.logout')}
          </Button>
        </Box>
      </Menu>
    </>
  );
};
export default withStyles(styles)(withRouter(MobileMenu));
